
import React, { useEffect, useState, useRef } from 'react';
import { Container, Button, Input, Form, FormGroup, Label, Row, Col, Select, Alert, Card, CardBody, CardTitle, CardText, } from 'reactstrap';
// import Confetti from 'react-confetti';
import { useParams } from 'react-router-dom';
import uploadFile from '../Reports/s3/s3.upload';
import { API_BASE_URL, API_KEY, getCookie, setCookie } from '../../constants/utilis';
import axios from 'axios';


const steps = [
    "CUSTOMER PIC 1",
    "CUSTOMER PIC 2",
    "CUSTOMER PIC 3",
    "PERSONAL INFORMATION",
    "RESIDENCE INFORMATION",
    "FAMILY INFORMATION",
    "ANY OTHER LOAN AVAILED",
    "OTHER INFORMATION",
    "CROSS VERIFICATION INFORMATION",
    "FEEDBACK"
];

const SelfEvaluationLoc = () => {
    // const navigate = useNavigate();
    // 6718ba7795afac8d8855396d
    // 671f26a28056de9efe2a1440
    // const { id } = useParams();
    const id = '671f26a28056de9efe2a1440'; // testing purpose
    const [currentStep, setCurrentStep] = useState(-1);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const capturedImages = useRef([]);
    const [address, setAddress] = useState('');
    const [latLng, setLatLng] = useState(null);
    const [cameraAccess, setCameraAccess] = useState(null);
    const [timer, setTimer] = useState(300);
    const [isAddressReady, setIsAddressReady] = useState(false);
    const [userData, setUserData] = useState([]);
    const [containerWidth, setContainerWidth] = useState('60%');
    const [formData, setFormData] = useState({
        qaddressconfirmed: '',
        qstayconfirmed: '',
        qpersonmet: '',
        qpersonrelationship: '',
        qstability: '',
        qage: '',
        qeducation: '',
        qresidenceownership: '',
        qfamilymemberscount: '',
        qearningmemberscount: '',
        qspouseworking: '',
        qearningmembers: '',
        qpermanentaddress: '',
        qcontactperson:  '',
        qrent: '',
        qverifiedfrom: '',
        qarea: '',
        qeaseoflocate: '',
        qtypeofresidence: '',
        qlocalityofresidence: '',
        qareaofresidence: '',
        qresidenceconstruction: '',
        qcommentonexterior: '',
        qcommentoninterior: '',
        qassetseenatresidence: '',
        qtpc1check: '',
        qtpc1: '',
        qtpc2check: '',
        qtpc2: '',
        qnegativefeedback: '',
        qoccupation: '',
        qapproxincome: '',
        qbranchdistance: '',
        qotherinformation: '',
        qcpvstatus: '',
    });

    const yesNoOptions = [
        { value: '', label: 'Select' },
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' },
    ];

    const verifiedOptions = [
        { label: 'Company Based', value: 'Company Based' },
        { label: 'Name Plate', value: 'Name Plate' },
        { label: 'Society Board', value: 'Society Board' },
        { label: 'Watchman', value: 'Watchman' },
    ];
    

    const config = {
        headers: {
          'apikey': API_KEY,
          'Authorization': "Bearer " + getCookie("at"),
          'Content-Type': 'application/json'
        }
    };

    // 671b03f91d27279519e91968
    // 671f26a28056de9efe2a1440 // test id
    // console.log("id",id) 
    useEffect(() => {
        console.log("id2", id);
    }, [id]); 

    useEffect(() => {
        let stream = null;

        const startCamera = async () => {
            try {
                stream = await navigator.mediaDevices.getUserMedia({ video: true });
                setCameraAccess(true);
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            } catch (err) {
                console.error('Error accessing camera:', err);
                setCameraAccess(false);
            }
        };

        const stopCamera = () => {
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
        };

        // Start camera when in relevant steps
        if (currentStep >= 0 && currentStep < steps.length - 7) {
            startCamera();
        } else {
            stopCamera(); // Stop camera when not in relevant steps
        }

        // Cleanup on unmount or when currentStep changes
        return () => {
            stopCamera();
        };
    }, [currentStep]);


    // Function to fetch the address using reverse geocoding
    const fetchAddress = async (lat, lng) => {
        try {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCJi9IR38yVxFmeir2yV6LZiZicrsZyIak`
            );
            const data = await response.json();
            if (data.results && data.results.length > 0) {
                setAddress(data.results[0].formatted_address); // Set the fetched address
                setIsAddressReady(true);
            }
        } catch (error) {
            console.error('Error fetching address:', error);
        }
    };

    const getCoordinates = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLatLng({ lat: latitude, lng: longitude }); // Set coordinates
                    fetchAddress(latitude, longitude); // Fetch address using coordinates
                    console.log(latitude, longitude, "coordinates");
                },
                (error) => {
                    console.error('Error getting location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const dataURLtoBlob = (dataUrl) => {
        const byteString = atob(dataUrl.split(',')[1]);
        const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    // Function to draw and wrap text
    function drawText(text, x, y, maxWidth, context) {
        const words = text.split(' ');
        let line = '';
        let lineHeight = parseInt(context.font, 10) + 5; // Adjust line height

        for (let n = 0; n < words.length; n++) {
            const testLine = line + words[n] + ' ';
            const metrics = context.measureText(testLine);
            const testWidth = metrics.width;

            if (testWidth > maxWidth && n > 0) {
                context.fillText(line, x, y);
                line = words[n] + ' ';
                y += lineHeight; // Move to next line
            } else {
                line = testLine;
            }
        }
        context.fillText(line, x, y); // Draw the last line
    }

    const captureImage = async () => {
        getCoordinates();
        if (videoRef.current && canvasRef.current && isAddressReady) {
            const context = canvasRef.current.getContext('2d');
            if (context) {
                const videoWidth = videoRef.current.videoWidth;
                const videoHeight = videoRef.current.videoHeight;

                const isPortrait = videoHeight > videoWidth;
                canvasRef.current.width = isPortrait ? videoHeight : videoWidth;
                canvasRef.current.height = isPortrait ? videoWidth : videoHeight;
                context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

                const currentDateTime = new Date().toLocaleString();
                const padding = 15; // Set padding from the edges
                const maxWidth = canvasRef.current.width - padding;

                context.font = '16px Arial';
                context.fillStyle = '#7000e4';
                context.textAlign = 'right';

                drawText(currentDateTime, canvasRef.current.width - padding, canvasRef.current.height - 50, maxWidth, context);
                drawText(address, canvasRef.current.width - padding, canvasRef.current.height - 30, maxWidth, context);

                const dataUrl = canvasRef.current.toDataURL('image/jpeg');
                const blob = dataURLtoBlob(dataUrl);

                const key = `images/${id}/${Date.now()}_.jpg`;

                try {
                    const fileUrl = await uploadFile(key, blob, 'image/jpeg');
                    capturedImages.current[currentStep] = fileUrl;
                    console.log("Image uploaded to:", fileUrl);
                    await saveEvaluationData();
                } catch (error) {
                    console.error("Error uploading image:", error);
                }

                if (currentStep < steps.length - 1) {
                    setCurrentStep(currentStep + 1);
                } else {
                    setCurrentStep(steps.length);
                }
            }
        }
    };

    const handleBackStep = async () => {
        setCurrentStep(currentStep - 1);
    }


    const handleNextStep = async () => {
        if (currentStep === steps.length - 7 ) {
            if (!formData.qaddressconfirmed || !formData.qstayconfirmed || !formData.qpersonmet || !formData.qpersonrelationship || !formData.qstability
               || !formData.qage || !formData.qeducation
            ) {
                alert('Please enter all details.');
                return;
            }
        } else if (currentStep === steps.length - 6) {
            if (!formData.qresidenceownership) {
                alert('Please Select Ownership Residence.');
                return;
            }
        } 
        else if (currentStep === steps.length - 5) {
            if (!formData.qearningmembers) {
                alert('Please Select Earning Members.');
                return;
            }
        } 
        else if (currentStep === steps.length - 4) {
            if (!formData.qverifiedfrom || !formData.qarea) {
                alert('Please Select Required Details.');
                return;
            }
        } 
        else if (currentStep === steps.length - 3) {
            if (!formData.qeaseoflocate || !formData.qtypeofresidence) {
                alert('Please Select All Required Details.');
                return;
            }
        } 
        else if (currentStep === steps.length - 2) {
            if (!formData.qtpc1check || !formData.qtpc2check || !formData.qapproxincome || !formData.qbranchdistance || !formData.qotherinformation) {
                alert('Please Fill All Required Details.');
                return;
            }
        } 
        else if (currentStep === steps.length - 1) {
            if (!formData.qcpvstatus) {
                alert('Please select the details.');
                return;
            }
            await saveEvaluationData();
            setCurrentStep(currentStep + 1);
            return;
        }

        setCurrentStep(currentStep + 1);
    };

    const saveEvaluationData = async () => {
        try {
            const formDataWithStatus = {
                ...formData,
                photos: capturedImages.current,
                locationcoordinates: latLng?.lat +", "+ latLng?.lng,
            };
            // console.log('Update successfully check', formDataWithStatus);

            await axios.put(API_BASE_URL + `/auth/case/${id}`, formDataWithStatus, config);
            console.log('Update successfully', formDataWithStatus);
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    useEffect(() => {
        if (timer > 0 && currentStep >= 0 && currentStep < steps.length) {
          const interval = setInterval(() => {
            setTimer(timer - 1);
          }, 1000);
    
          return () => clearInterval(interval);
        } else if (timer === 0) {
        saveEvaluationData();
          setCurrentStep(steps.length);
          setCameraAccess(null);
          console.log('Timer reset due to timeout');
        }
    }, [timer, currentStep]);

    useEffect(() => {
        if (cameraAccess && videoRef.current && videoRef.current.srcObject === null) {
            navigator.mediaDevices.getUserMedia({
                // to check on the desktop uncomment this video: true
                video: true
            })
                .then(stream => {
                    videoRef.current.srcObject = stream;
                    videoRef.current.play();
                })
                .catch(error => {
                    console.error('Error accessing camera:', error);
                });
        }
    }, []);


    useEffect(() => {
        const fetchLogin = async () => {
            const username = 'dev';
            const password = 'dev123';

            try {
                const { data } = await axios.post(API_BASE_URL +  '/api/webuser', { username, password }, config);
                // console.log("Eval",data);

                localStorage.setItem('token', data.accessToken);
                setCookie('at', data.accessToken);
                setCookie('rt', data.refreshToken);

            } catch (error) {
                console.error("Error during auto login:", error);
            }

            try {
                const response = await axios.get(API_BASE_URL + `/auth/case/${id}`, config);
                setUserData(response.data);
                // console.log("data", response.data)
              } catch (error) {
                console.error("Error fetching vehicle details:", error);
              }

        };

        fetchLogin();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
        // const { name, value, checked } = e.target;

        // if (name === 'qverifiedfrom') {
        //     if (checked) {
        //         setFormData(prevState => ({
        //             ...prevState,
        //             [name]: [...prevState[name], value],
        //         }));
        //     } else {
        //         setFormData(prevState => ({
        //             ...prevState,
        //             [name]: prevState[name].filter(item => item !== value),
        //         }));
        //     }
        // } else {
        //     setFormData(prevState => ({
        //         ...prevState,
        //         [name]: value,
        //     }));
        // }
    
    };
    

    const updateWidth = () => {
        if (window.innerWidth < 576) {
            setContainerWidth('95%');
        }
        else if (window.innerWidth > 576 && window.innerWidth <= 768) {
            setContainerWidth('70%');
        } else {
            setContainerWidth('50%');
        }
    };

    useEffect(() => {
        updateWidth();

        window.addEventListener('resize', updateWidth);

        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []); 
    
    return (
        <Container style={{ margin: 'auto', padding: '15px', width: containerWidth, marginTop: '10px', textAlign: 'center', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '10px'}}>
            {currentStep === -1 && (
                <Card>
                    <CardBody>
                        <CardTitle tag="h5" style={{ color: '#0063cc', fontWeight: '600', marginBottom: '30px', fontSize: '18px'}}>Application Details</CardTitle>
                        <Row>
                            <Col sm="12">
                                <CardText>
                                    <strong>Application Id:</strong> <span style={{ color: 'orange' }}>{userData?.applicationid || 'NA'}</span>
                                </CardText>
                                <CardText>
                                    <strong>Customer Name:</strong> <span style={{ color: 'orange' }}>{userData?.customername || 'NA'}</span>
                                </CardText>
                                <CardText>
                                    <strong>Product Name:</strong> <span style={{ color: 'orange' }}>{userData?.productname || 'NA'}</span>
                                </CardText>
                            </Col>
                            <Col sm="12">
                                <CardText>
                                    <strong>Mobile Number:</strong> <span style={{ color: 'orange' }}>{userData?.mobilenumber || 'NA'}</span>
                                </CardText>
                                <CardText>
                                    <strong>FI Type:</strong> <span style={{ color: 'orange' }}>{userData?.fi || 'NA'}</span>
                                </CardText>
                                <CardText>
                                    <strong>Bank:</strong> <span style={{ color: 'orange' }}>{userData?.bank || 'NA'}</span>
                                </CardText>
                                <CardText>
                                    <strong>Residence Address:</strong> <span style={{ color: 'orange' }}>{userData?.residenceaddress || 'NA'}</span>
                                </CardText>
                            </Col>
                        </Row>
                        <Button onClick={handleNextStep} color="primary" style={{ marginTop: '20px' }}>
                            Start Verification
                        </Button>
                    </CardBody>
                </Card>
            )}
            {currentStep >= 0 && currentStep < steps.length - 7 && (
                <Card>
                    <h5>Step {currentStep + 1}: {steps[currentStep]}</h5>
                    <video ref={videoRef} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} autoPlay playsInline muted />
                    <Button onClick={captureImage} color="success" style={{ marginTop: '10px' }}>
                        Capture Image
                    </Button>
                </Card>
            )}

            {currentStep === steps.length - 7 && (
                <Form style={{ margin: 'auto', textAlign: 'start', marginTop: '20px' }}>
                    <h5 style={{ color: '#0063cc', fontWeight: '600'}}>{steps[currentStep]}</h5>
                    <FormGroup>
                        <Label for="qaddressconfirmed">Address Confirmation *</Label>
                        <Input
                            id="qaddressconfirmed"
                            type="select"
                            name="qaddressconfirmed"
                            value={formData.qaddressconfirmed}
                            onChange={handleInputChange}
                        >
                            {yesNoOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="qstayconfirmed">Stay Confirmation *</Label>
                        <Input
                            id="qstayconfirmed"
                            type="select"
                            name="qstayconfirmed"
                            value={formData.qstayconfirmed}
                            onChange={handleInputChange}
                        >
                            {yesNoOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="qpersonmet">Name of the Person Met *</Label>
                        <Input
                            id="qpersonmet"
                            type="text"
                            name="qpersonmet"
                            value={formData.qpersonmet}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="qpersonrelationship">Relation with Applicant *</Label>
                        <Input
                            id="qpersonrelationship"
                            type="select"
                            name="qpersonrelationship"
                            value={formData.qpersonrelationship}
                            onChange={handleInputChange}
                        >
                            <option value="">Select Relation</option>
                            <option value="Self">Self</option>
                            <option value="Father">Father</option>
                            <option value="Mother">Mother</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="qstability">Stability *</Label>
                        <Input
                            id="qstability"
                            type="select"
                            name="qstability"
                            value={formData.qstability}
                            onChange={handleInputChange}
                        >
                            <option value="">Select Stability</option>
                            <option value="By Birth">By Birth</option>
                            <option value="Others">Others</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="qage">Enter Age *</Label>
                        <Input
                            id="qage"
                            type="text"
                            name="qage"
                            value={formData.qage}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="qeducation">Education *</Label>
                        <Input
                            id="qeducation"
                            type="select"
                            name="qeducation"
                            value={formData.qeducation}
                            onChange={handleInputChange}
                        >
                            <option value="">Select Education</option>
                            <option value="Graduate">Graduate</option>
                            <option value="Post Graduate">Post Graduate</option>
                            <option value="Under Graduate">Under Graduate</option>
                            <option value="Others">Others</option>
                        </Input>
                    </FormGroup>
                    <Button onClick={handleNextStep} color="primary">
                        Next
                    </Button>
                </Form>
            )}

            {currentStep === steps.length - 6 && (
                <Form style={{ margin: 'auto', textAlign: 'start', marginTop: '20px' }}>
                    <Button outline onClick={handleBackStep} color="secondary" style={{ marginBottom: '10px', width: '50px' }}>
                        &#8592; {/* Unicode for left arrow */}
                    </Button>
                    <h5 style={{ color: '#0063cc', fontWeight: '600'}}>{steps[currentStep]}</h5>
                    <FormGroup>
                        <Label for="qresidenceownership">Ownership Residence *</Label>
                        <Input
                            id="qresidenceownership"
                            type="select"
                            name="qresidenceownership"
                            value={formData.qresidenceownership}
                            onChange={handleInputChange}
                        >
                            <option value="">Select Residence</option>
                            <option value="Self Owned">Self Owned</option>
                            <option value="Owned By Parents">Owned By Parents</option>
                            <option value="Owned By Relative">Owned By Relative</option>
                            <option value="Rented">Rented</option>
                            <option value="Company Accommodation">Company Accommodation</option>
                            <option value="Paying Guest">Paying Guest</option>
                        </Input>
                    </FormGroup>
                    <Button onClick={handleNextStep} color="primary">
                        Next
                    </Button>
                </Form>
            )}

            {currentStep === steps.length - 5 && (
                <Form style={{ margin: 'auto', textAlign: 'start', marginTop: '20px' }}>
                    <Button outline onClick={handleBackStep} color="secondary" style={{ marginBottom: '10px', width: '50px' }}>
                        &#8592; {/* Unicode for left arrow */}
                    </Button>
                    <h5 style={{ color: '#0063cc', fontWeight: '600'}}>{steps[currentStep]}</h5>
                    <FormGroup>
                        <Label for="qfamilymemberscount">Total Family Members</Label>
                        <Input
                            id="qfamilymemberscount"
                            type="text"
                            name="qfamilymemberscount"
                            value={formData.qfamilymemberscount}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="qearningmemberscount">Number of Earnings Members</Label>
                        <Input
                            id="qearningmemberscount"
                            type="tel"
                            name="qearningmemberscount"
                            value={formData.qearningmemberscount}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="qspouseworking">Spouse Working</Label>
                        <Input
                            id="qspouseworking"
                            type="select"
                            name="qspouseworking"
                            value={formData.qspouseworking}
                            onChange={handleInputChange}
                        >
                            {yesNoOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="qearningmembers">Earning Members *</Label>
                        <Input
                            id="qearningmembers"
                            type="select"
                            name="qearningmembers"
                            value={formData.qearningmembers}
                            onChange={handleInputChange}
                        >
                            <option value="">Select</option>
                            <option value="Brother">Brother</option>
                            <option value="Daughter">Daughter</option>
                            <option value="Father">Father</option>
                            <option value="Mother">Mother</option>
                            <option value="Sister">Sister</option>
                            <option value="Husband">Husband</option>
                            <option value="Son">Son</option>
                        </Input>
                    </FormGroup>
                    <Button onClick={handleNextStep} color="primary">
                        Next
                    </Button>
                </Form>
            )}

            {currentStep === steps.length - 4 && (
                <Form style={{ margin: 'auto', textAlign: 'start', marginTop: '20px' }}>
                    <Button outline onClick={handleBackStep} color="secondary" style={{ marginBottom: '10px', width: '50px' }}>
                        &#8592; {/* Unicode for left arrow */}
                    </Button>
                    <h5 style={{ color: '#0063cc', fontWeight: '600'}}>{steps[currentStep]}</h5>
                    <FormGroup>
                        <Label>Permanent Address</Label>
                        <Input
                            id="qpermanentaddress"
                            type="text"
                            name="qpermanentaddress"
                            value={formData.qpermanentaddress}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Contact Person</Label>
                        <Input
                            id="qcontactperson"
                            type="text"
                            name="qcontactperson"
                            value={formData.qcontactperson}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Rent Per Month</Label>
                        <Input
                            id="qrent"
                            type="text"
                            name="qrent"
                            value={formData.qrent}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="qverifiedfrom">Verified From *</Label>
                        <div style={{ marginLeft: '30px'}}>
                            {verifiedOptions.map(option => (
                                <div key={option.value}>
                                    <Input
                                        type="checkbox"
                                        id={option.value}
                                        name="qverifiedfrom" // Keep the same name for grouping
                                        value={option.value}
                                        checked={formData.qverifiedfrom.includes(option.value)} // Check if the option is selected
                                        onChange={handleInputChange} // Call the same handler
                                    />
                                    <Label for={option.value} style={{ marginLeft: '5px' }}>
                                        {option.label}
                                    </Label>
                                </div>
                            ))}
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Label for="qarea">Area (In Sqft) *</Label>
                        <Input
                            id="qarea"
                            type="select"
                            name="qarea"
                            value={formData.qarea}
                            onChange={handleInputChange}
                        >
                            <option value="">Select</option>
                            <option value="Greater Than 400">Greater Than 400</option>
                            <option value="400 To 800">400 To 800</option>
                            <option value=">800 To 1200">800 To 1200</option>
                            <option value=">1200 To 1500">1200 To 1500</option>
                            <option value=">1500">Greater Than 1500</option>
                        </Input>
                    </FormGroup>
                    <Button onClick={handleNextStep} color="primary">
                        Next
                    </Button>
                </Form>
            )}

            {currentStep === steps.length - 3 && (
                <Form style={{ margin: 'auto', textAlign: 'start', marginTop: '20px' }}>
                    <Button outline onClick={handleBackStep} color="secondary" style={{ marginBottom: '10px', width: '50px' }}>
                        &#8592; {/* Unicode for left arrow */}
                    </Button>
                    <h5 style={{ color: '#0063cc', fontWeight: '600'}}>{steps[currentStep]}</h5>
                    <FormGroup>
                        <Label>Ease of Locate Address *</Label>
                        <Input
                            id="qeaseoflocate"
                            type="select"
                            name="qeaseoflocate"
                            value={formData.qeaseoflocate}
                            onChange={handleInputChange}
                        >
                            <option value="">Select</option>
                            <option value="Easy">Easy</option>
                            <option value="Difficult">Difficult</option>
                            <option value="Untraceable">Untraceable</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Landmark</Label>
                        <Input
                            id="qlandmark"
                            type="text"
                            name="qlandmark"
                            value={formData.qlandmark}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Type of Residence *</Label>
                        <Input
                            id="qtypeofresidence"
                            type="select"
                            name="qtypeofresidence"
                            value={formData.qtypeofresidence}
                            onChange={handleInputChange}
                        >
                            <option value="">Select</option>
                            <option value="Bungalow">Bungalow</option>
                            <option value="Hutmen/Sitting Chawl">Hutmen/Sitting Chawl</option>
                            <option value="Independent House">Independent House</option>
                            <option value="Multi Tenant House">Multi Tenant House</option>
                            <option value="Part Of Independent House">Part Of Independent House</option>
                            <option value="Row House">Row House</option>
                            <option value="Temporary Shed">Temporary Shed</option>
                            <option value="Standing Chwal/Janta Flat">Standing Chwal/Janta Flat</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Locality of Residence</Label>
                        <Input
                            id="qlocalityofresidence"
                            type="select"
                            name="qlocalityofresidence"
                            value={formData.qlocalityofresidence}
                            onChange={handleInputChange}
                        >
                            <option value="">Select</option>
                            <option value="Lower Middle Class">Lower Middle Class</option>
                            <option value="Middle Class">Middle Class</option>
                            <option value="Posh Locality">Posh Locality</option>
                            <option value="Slums">Slums</option>
                            <option value="Upper Middle Class">Upper Middle Class</option>
                            <option value="Village Area">Village Area</option>
                            <option value="Temporary Shed">Other</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Area of Residence</Label>
                        <Input
                            id="qareaofresidence"
                            type="select"
                            name="qareaofresidence"
                            value={formData.qareaofresidence}
                            onChange={handleInputChange}
                        >
                            <option value="">Select</option>
                            <option value="Negative Area">Negative Area</option>
                            <option value="Non Negative Area">Non Negative Area</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Residence Construction</Label>
                        <Input
                            id="qresidenceconstruction"
                            type="select"
                            name="qresidenceconstruction"
                            value={formData.qresidenceconstruction}
                            onChange={handleInputChange}
                        >
                            <option value="">Select</option>
                            <option value="Pukka">Pukka</option>
                            <option value="Semi-Pakka">Semi-Pakka</option>
                            <option value="Temporary">Temporary</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Comment on Exterior</Label>
                        <Input
                            id="qcommentonexterior"
                            type="select"
                            name="qcommentonexterior"
                            value={formData.qcommentonexterior}
                            onChange={handleInputChange}
                        >
                            <option value="">Select</option>
                            <option value="Average">Average</option>
                            <option value="Building Wall">Building Wall</option>
                            <option value="Car Park">Car Park</option>
                            <option value="Elevator">Elevator</option>
                            <option value="Fenced / Compound Wall">Fenced / Compound Wall</option>
                            <option value="Garden">Garden</option>
                            <option value="Poor">Poor</option>
                            <option value="Security">Security</option>
                            <option value="Others">Others</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Interior</Label>
                        <Input
                            id="qcommentoninterior"
                            type="select"
                            name="qcommentoninterior"
                            value={formData.qcommentoninterior}
                            onChange={handleInputChange}
                        >
                            <option value="">Select</option>
                            <option value="Average">Average</option>
                            <option value="Carpeted">Carpeted</option>
                            <option value="Clean">Clean</option>
                            <option value="Good">Good</option>
                            <option value="Painted">Painted</option>
                            <option value="Sofa">Sofa</option>
                            <option value="Venetian Blinds">Venetian Blinds</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label >Asset Seen at residence</Label>
                        <Input
                            id="qassetseenatresidence"
                            type="select"
                            name="qassetseenatresidence"
                            value={formData.qassetseenatresidence}
                            onChange={handleInputChange}
                        >
                            <option value="">Select</option>
                            <option value="Air Conditioner">Air Conditioner</option>
                            <option value="Car">Car</option>
                            <option value="Furniture A/c">Furniture A/c</option>
                            <option value="Music System">Music System</option>
                            <option value="Refrigerator">Refrigerator</option>
                            <option value="Television">Television</option>
                        </Input>
                    </FormGroup>
                    <Button onClick={handleNextStep} color="primary">
                        Next
                    </Button>
                </Form>
            )}

            {currentStep === steps.length - 2 && (
                <Form style={{ margin: 'auto', textAlign: 'start', marginTop: '20px' }}>
                    <Button outline onClick={handleBackStep} color="secondary" style={{ marginBottom: '10px', width: '50px' }}>
                        &#8592; {/* Unicode for left arrow */}
                    </Button>
                    <h5 style={{ color: '#0063cc', fontWeight: '600'}}>{steps[currentStep]}</h5>
                    <FormGroup>
                        <Label for="qtpc1check">Third Party Check 1 *</Label>
                        <Input
                            id="qtpc1check"
                            type="text"
                            name="qtpc1check"
                            required
                            value={formData.qtpc1check}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Status</Label>
                        <Input
                            id="qtpc1"
                            type="select"
                            name="qtpc1"
                            value={formData.qtpc1}
                            onChange={handleInputChange}
                        >
                            {yesNoOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="qtpc2check">Third Party Check 2 *</Label>
                        <Input
                            id="qtpc2check"
                            type="text"
                            name="qtpc2check"
                            value={formData.qtpc2check}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Status</Label>
                        <Input
                            id="qtpc2"
                            type="select"
                            name="qtpc2"
                            value={formData.qtpc2}
                            onChange={handleInputChange}
                        >
                            {yesNoOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="qnegativefeedback">Negative Feedback, If Any</Label>
                        <Input
                            id="qnegativefeedback"
                            type="text"
                            name="qnegativefeedback"
                            value={formData.qnegativefeedback}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Applicant Occupation</Label>
                        <Input
                            id="qoccupation"
                            type="select"
                            name="qoccupation"
                            value={formData.qoccupation}
                            onChange={handleInputChange}
                        >
                            <option value="">Select</option>
                            <option value="Student">Student</option>
                            <option value="Housewife">Housewife</option>
                            <option value="Job">Job</option>
                            <option value="Business">Business</option>
                            <option value="Agriculture">Agriculture</option>
                            <option value="Rental Income">Rental Income</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Enter Applicant Income *</Label>
                        <Input
                            id="qapproxincome"
                            type="text"
                            name="qapproxincome"
                            value={formData.qapproxincome}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Distance From Branch (In km) *</Label>
                        <Input
                            id="qbranchdistance"
                            type="text"
                            name="qbranchdistance"
                            value={formData.qbranchdistance}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Any other information *</Label>
                        <Input
                            id="qotherinformation"
                            type="text"
                            name="qotherinformation"
                            value={formData.qotherinformation}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <Button onClick={handleNextStep} color="primary">
                        Next
                    </Button>
                </Form>
            )}

            {currentStep === steps.length - 1 && (
                <Form style={{ margin: 'auto', textAlign: 'start', marginTop: '20px' }}>
                    <Button outline onClick={handleBackStep} color="secondary" style={{ marginBottom: '10px', width: '50px' }}>
                        &#8592; {/* Unicode for left arrow */}
                    </Button>
                    <h5 style={{ color: '#0063cc', fontWeight: '600'}}>{steps[currentStep]}</h5>
                    
                    <FormGroup>
                        <Label>CPV Status *</Label>
                        <Input
                            id="qcpvstatus"
                            type="select"
                            name="qcpvstatus"
                            value={formData.qcpvstatus}
                            onChange={handleInputChange}
                        >
                            <option value="">Select</option>
                            <option value="Positive">Positive</option>
                            <option value="Negative">Negative</option>
                            <option value="Refer to Credit">Refer to Credit</option>
                            <option value="Neutral (UnDecided)">Neutral (UnDecided)</option>
                            <option value="Not Completed">Not Completed</option>
                        </Input>
                    </FormGroup>
                    <Button onClick={handleNextStep} color="primary">
                        Submit
                    </Button>
                </Form>
            )}

            {currentStep >= steps.length && (
                <Alert color="success" style={{ marginTop: '20px' }}>
                    All steps completed! Verification successful.
                </Alert>
            )}

            {currentStep < steps.length && (
                <h6 style={{ marginTop: '20px', color: 'red' }}>
                    Time remaining: {Math.floor(timer / 60)}:{timer % 60 < 10 ? '0' : ''}{timer % 60}
                </h6>
            )}
            <canvas ref={canvasRef} style={{ display: 'none' }} />
        </Container>
    );
};

export default SelfEvaluationLoc;