import s3config from "./s3.config";
import AWS from 'aws-sdk';

const uploadFile = async (key, body, contentType) => {
    const S3_BUCKET = "locationtec-case-images";
    const REGION = "ap-south-1";

    AWS.config.update(s3config);
    const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    });

    console.log("S3 content", contentType);

    let params = {
        Bucket: S3_BUCKET,
        Key: key,
        Body: body,
        ContentType: contentType,
        // ACL: 'public-read'
    };

    try {
        const response = await s3.putObject(params).promise();
        console.log("File uploaded successfully.", response);

        params = {
            Bucket: S3_BUCKET,
            Key: key,
        };
        // const fileUrl = await s3.getSignedUrlPromise('getObject', params);
        // Generate the URL for the uploaded file
        const fileUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${key}`;
        console.log("File URL:", fileUrl);

        // throw "error";
        return fileUrl;
    } catch (error) {
        console.error("Error uploading file:", error);
        throw error;
    }
};

export default uploadFile;
