import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faMarker } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import Select from 'react-select';
import GoogleMap from 'google-map-react';
import { Tooltip } from "bootstrap";
import CardTitle from "reactstrap/lib/CardTitle";
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";
import uploadFile from "./s3/s3.upload";


class EditCaseDetails extends Component {
  constructor(props) {
    super(props);
    this.fetchList = this.fetchList.bind(this);
    this.handleUpdate1 = this.handleUpdate1.bind(this);
    this.handleUpdate2 = this.handleUpdate2.bind(this);
    this.handleUpdate3 = this.handleUpdate3.bind(this);
    this.enableToClient = this.enableToClient.bind(this);
    this.disableToClient = this.disableToClient.bind(this);
    this.funcMap = {
        '1': this.fetchList,
        '2': this.handleUpdate1,
        '3': this.handleUpdate2,
        '4': this.handleUpdate3,
        '5': this.enableToClient,
        '6': this.disableToClient
    };
    this.state = {
      firebaseData: [],
      menu: 'Select',
      success_msg: false,
      agents: [],
      home: false,
      q: '',

    qAddressConfirmed: '',
    qPersonMet: '',
    qPersonRelationship: '',
    qPersonStability: '',
    qTotalAgriLand: '',
    qLandDetails: '',
    qIrrigationSource: '',
    qCropType: '',
    qesidenceOwnership: '',
    qFamilyMembersCount: '',
    qEarningMembersCount: '',
    qOfficeAddress: '',
    qResidenceProofId: '',
    qAgricultureLending: '',
    qInterior: '',
    qExterior: '',
    qNeighbour1: '',
    qNeighbour2: '',
    qCpvStatus: '',
    qRejectReason: '',
    qAgentRemarks: '',
    agentSignature: '',
    qPersonMet: '',
    qPersonMetDesignation: '',
    qCoOffice: '',
    qAddressConfirmed: '',
    qPersonDesignation: '',
    qResidentAddress: '',
    qNature: '',
    qOfficeOwnership: '',
    qWorkingSince: '',
    qApproxIncome: '',
    qNumberEmployees: '',
    qStocks: '',
    qBusinessProof: '',
    qBusinessActivity: '',
    qSignBoardText: '',
    qOfficeSeenText: '',
    qVehicleDetails: '',
    qStability: '',
    qustomerSignature: '',
    qTpc1: '',
    qTpc1Check: '',
    qTpc2: '',
    qTpc2Check: '',
    qCpvStatus: '',
    qRejectReason: '',
    qAgentRemarks: '',
    agentSignature: '',
    qAddressConfirmed: '',
    qPersonMet: '',
    qPersonRelationship: '',
    qPersonStability: '',
    qResidenceOwnership: '',
    qFamilyMembersCount: '',
    qEarningMembersCount: '',
    qSpouseWorking: '',
    qEarningMembers: '',
    qNbfcName: '',
    qLoanAmount: '',
    qPurpose: '',
    qResidenceStatus: '',
    qPermanentAddress: '',
    qContactPerson: '',
    qRent: '',
    qVerifiedFrom: '',
    qArea: '',
    qAssetDetails: '',
    qAssetAppliedFor: '',
    qAssetToBeUsedBy: '',
    qEaseOfLocate: '',
    qLandmark: '',
    qTypeOfResidence: '',
    qLocalityOfResidence: '',
    qAreaofResidence: '',
    qResidenceConstruction: '',
    qCommentOnExterior: '',
    qCommentOnInterior: '',
    qAssetSeenAtResidence: '',
    qTwoWheeler: '',
    qTwoWheelerModelName: '',
    qTwoWheelerYear: '',
    qCar: '',
    qCarModelName: '',
    qCarYear: '',
    qVehicleUsedBy: '',
    qPersonNameVerification: '',
    qApplicantDetailsConfirmed: '',
    qNegativeFeedback: '',
    qResidenceLocked: '',
    qCpvStatus: '',
    qRejectReason: '',
    productName: '',
    customerName: '',
    amount: '',
    bank: '',
    fi: '',

    markers: [],
    markerLatitude: '',
    markerLongitude: '',
    myLatLong: {},

    applicationId: "",
    showToClient: '',
    branchName: '',
    photos: [],
    };
  }

  componentDidMount() {
    let comp = this

    comp.fetchList()

  }

  async fetchList() {

    let comp = this

    let caseId = sessionStorage.getItem("caseId")

    var config = {
        method: "get",
        url: API_BASE_URL + "/auth/case/" + caseId,
        headers: {
          apikey: API_KEY,
          Authorization: "Bearer " + getCookie("at"),
        },
      }
  
      axios(config)
        .then(function (response) {
          if (response.status == 200) {
            let residentAddress;
            let vehicleDetails;

            if(response.data.qresidentaddress !== undefined) {
                residentAddress = response.data.qresidentaddress
            } else {
                residentAddress = ""
            }

            if(response.data.qvehicledetails !== undefined) {
                vehicleDetails = response.data.qvehicledetails
            } else {
                vehicleDetails = ""
            }

            comp.setState({
                qAddressConfirmed:response.data.qaddressconfirmed,
                qStayConfirmed: response.data.qstayconfirmed,
                qPersonMet:response.data.qpersonmet,
                qPersonRelationship:response.data.qpersonrelationship,
                qPersonStability:response.data.qpersonstability,
                qTotalAgriLand:response.data.qtotalagriland,
                qLandDetails:response.data.qlanddetails,
                qIrrigationSource:response.data.qirrigationsource,
                qCropType:response.data.qcroptype,
                qesidenceOwnership:response.data.residenceownership,
                qAge: response.data.qage,
                qEducation: response.data.qeducation,
                qFamilyMembersCount:response.data.qfamilymemberscount,
                qEarningMembersCount:response.data.qearningmemberscount,
                qOfficeAddress:response.data.qofficeaddress,
                officeAddress:response.data.officeaddress,
                qResidenceProofId:response.data.qresidenceproofid,
                qAgricultureLending:response.data.qagriculturelending,
                qInterior:response.data.qinterior,
                qExterior:response.data.qexterior,
                qNeighbour1:response.data.qneighbour1,
                qNeighbour2:response.data.qneighbour2,
                qCpvStatus:response.data.qcpvstatus,
                qRejectReason:response.data.qrejectreason,
                qAgentRemarks:response.data.qagentremarks,
                agentSignature:response.data.agentsignature,

                qPersonMet: response.data.qpersonmet,
                qPersonMetDesignation: response.data.qpersonmetdesignation,
                qCoOffice: response.data.qcooffice,
                qAddressConfirmed: response.data.qaddressconfirmed,
                qPersonDesignation: response.data.qpersondesignation,
                qResidentAddress: response.data.qresidentaddress,
                qNature: response.data.qnature,
                qOfficeOwnership: response.data.qofficeownership,
                qWorkingSince: response.data.qworkingsince,
                qApproxIncome: response.data.qapproxincome,
                qNumberEmployees: response.data.qnumberemployees,
                qStocks: response.data.qstocks,
                qBusinessProof: response.data.qbusinessproof,
                qBusinessActivity: response.data.qbusinessactivity,
                qSignBoardText: response.data.qsignboardtext,
                qOfficeSeenText: response.data.qofficeseentext,
                qVehicleDetails: response.data.qvehicledetails,
                qStability: response.data.qstability,
                qustomerSignature: response.data.customersignature,
                qTpc1: response.data.qtpc1,
                qTpc1Check: response.data.qtpc1check,
                qTpc2: response.data.qtpc2,
                qTpc2Check: response.data.qtpc2check,
                qCpvStatus: response.data.qcpvstatus,
                qRejectReason: response.data.qrejectreason,
                qAgentRemarks: response.data.qagentremarks,
                agentSignature: response.data.agentsignature,

                qAddressConfirmed: response.data.qaddressconfirmed,
                qPersonMet: response.data.qpersonmet,
                qPersonRelationship: response.data.qpersonrelationship,
                qPersonStability: response.data.qpersonstability,
                qResidenceOwnership: response.data.qresidenceownership,
                qFamilyMembersCount: response.data.qfamilymemberscount,
                qEarningMembersCount: response.data.qearningmemberscount,
                qSpouseWorking: response.data.qspouseworking,
                qEarningMembers: response.data.qearningmembers,
                qNbfcName: response.data.qnbfcname,
                qLoanAmount: response.data.qloanamount,
                qPurpose: response.data.qpurpose,
                qResidenceStatus: response.data.qresidencestatus,
                qPermanentAddress: response.data.qpermanentaddress,
                qContactPerson: response.data.qcontactperson,
                qRent: response.data.qrent,
                qVerifiedFrom: response.data.qverifiedfrom,
                qArea: response.data.qarea,
                qAssetDetails: response.data.qassetdetails,
                qAssetAppliedFor: response.data.qassetappliedfor,
                qAssetToBeUsedBy: response.data.qassettobeusedby,
                qEaseOfLocate: response.data.qeaseoflocate,
                qLandmark: response.data.qlandmark,
                qTypeOfResidence: response.data.qtypeofresidence,
                qLocalityOfResidence: response.data.qlocalityofresidence,
                qAreaofResidence: response.data.qareaofresidence,
                qResidenceConstruction: response.data.Qqresidenceconstruction,
                qCommentOnExterior: response.data.qcommentonexterior,
                qCommentOnInterior: response.data.qcommentoninterior,
                qAssetSeenAtResidence: response.data.qassetseenatresidence,
                qTwoWheeler: response.data.qtwowheeler,
                qTwoWheelerModelName: response.data.qtwowheelermodelname,
                qTwoWheelerYear: response.data.qtwowheeleryear,
                qCar: response.data.qcar,
                qCarModelName: response.data.qcarmodelname,
                qCarYear: response.data.qcaryear,
                qVehicleUsedBy: response.data.qvehicleusedby,
                qPersonNameVerification: response.data.qpersonnameverification,
                qApplicantDetailsConfirmed: response.data.qapplicantdetailsconfirmed,
                qNegativeFeedback: response.data.qnegativefeedback,
                qOccupation: response.data.qoccupation,
                qLandDetails: response.data.qlandDetails,
                qBranchDistance: response.data.qbranchdistance,
                qIncome: response.data.qincome,
                qResidenceLocked: response.data.qresidencelocked,
                qCpvStatus: response.data.qcpvstatus,
                qRejectReason: response.data.qrejectreason,
                productName: response.data.productname,
                fi: response.data.fi,
                showToClient: response.data.showToClient,
                branchName: response.data.branchName,
                photos: response.data.photos,


                customerName: response.data.customername,
                amount: response.data.loanamount,
                bank: response.data.bank,

                applicationId: response.data.applicationid,
            })


            // if(doc.data().LocationCoordinates !== undefined) {
            //     comp.setState({
            //         markerLatitude: doc.data().LocationCoordinates.latitude,
            //         markerLongitude: doc.data().LocationCoordinates.longitude,
            //     })
            // }
        }
    })
    .catch(function (error) {
        console.log(error)
        comp.handleNon200Response(error, "1")
      })
  }

  handleNon200Response = (error, functionIndex) => {
    if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
       sessionExpired();
     } 
  }

  handleUpdate1(e) {
      let data = this.state

      let comp = this

      let caseId = sessionStorage.getItem("caseId")

      let jsonData = JSON.stringify({
            qaddressconfirmed:(data.qAddressConfirmed === undefined) ? "" : data.qAddressConfirmed,
            qpersonmet:(data.qPersonMet === undefined) ? "" : data.qPersonMet,
            qpersonrelationship:(data.qPersonRelationship === undefined) ? "" : data.qPersonRelationship,
            qpersonstability:(data.qPersonStability === undefined) ? "" : data.qPersonStability,
            qtotalagriland:(data.qTotalAgriLand === undefined) ? "" : data.qTotalAgriLand,
            qlanddetails:(data.qLandDetails === undefined) ? "" : data.qLandDetails,
            qirrigationsource:(data.qIrrigationSource === undefined) ? "" : data.qIrrigationSource,
            qcroptype:(data.qCropType === undefined) ? "" : data.qCropType,
            residenceownership:(data.qResidenceOwnership === undefined) ? "" : data.qResidenceOwnership,
            qfamilymemberscount:(data.qFamilyMembersCount === undefined) ? "" : data.qFamilyMembersCount,
            qearningmemberscount:(data.qEarningMembersCount === undefined) ? "" : data.qPersonMet,
            qofficeaddress:(data.qOfficeAddress === undefined) ? "" : data.qOfficeAddress,
            qresidenceproofid:(data.qResidenceProofId === undefined) ? "" : data.qResidenceProofId,
            qagriculturelending:(data.qAgricultureLending === undefined) ? "" : data.qAgricultureLending,
            qinterior:(data.qInterior === undefined) ? "" : data.qInterior,
            qexterior:(data.qExterior === undefined) ? "" : data.qExterior,
            qneighbour1:(data.qNeighbour1 === undefined) ? "" : data.qNeighbour1,
            qneighbour2:(data.qNeighbour2 === undefined) ? "" : data.qNeighbour2,
            qcpvstatus:(data.qCpvStatus === undefined) ? "" : data.qCpvStatus,
            qrejectreason:(data.qRejectReason === undefined) ? "" : data.qRejectReason,
            qagentremarks:(data.qAgentRemarks === undefined) ? "" : data.qAgentRemarks,
            agentsignature:(data.agentSignature === undefined) ? "" : data.agentSignature,
            branchName: (data.branchName === undefined) ? "" : data.branchName,
            photos: (data.photos === undefined) ? "" : data.photos,
        })

        var config = {
            method: "put",
            url: API_BASE_URL + "/auth/case/" + caseId,
            headers: {
              apikey: API_KEY,
              Authorization: "Bearer " + getCookie("at"),
              "Content-Type": "application/json",
            },
            data: jsonData,
          }
      
          axios(config)
            .then(function (response) {
              if (response.status == 200) {
                alert("Updated Successfully!!")        
                comp.setState({
                    home: true
                })
              }
            })
            .catch(function (error) {
              console.log(error)
              comp.handleNon200Response(error, "2")
        })
}

handleUpdate2(e) {
    let data = this.state

    let comp = this

    let caseId = sessionStorage.getItem("caseId")


    let jsonData = JSON.stringify({
          qpersonmet: (data.qPersonMet === undefined) ? "" : data.qPersonMet,
          qpersonmetdesignation: (data.qPersonMetDesignation === undefined) ? "" : data.qPersonMetDesignation,
          qcooffice: (data.qCoOffice === undefined) ? "" : data.qCoOffice,
          qaddressconfirmed: (data.qAddressConfirmed === undefined) ? "" : data.qAddressConfirmed,
          qpersondesignation: (data.qPersonDesignation === undefined) ? "" : data.qPersonDesignation,
          qresidentaddress: (data.qResidentAddress === undefined) ? "" : data.qResidentAddress,
          qnature: (data.qNature === undefined) ? "" : data.qNature,
          qofficeownership: (data.qOfficeOwnership === undefined) ? "" : data.qOfficeOwnership,
          qworkingsince: (data.qWorkingSince === undefined) ? "" : data.qWorkingSince,
          qapproxincome: (data.qApproxIncome === undefined) ? "" : data.qApproxIncome,
          qnumberemployees: (data.qNumberEmployees === undefined) ? "" : data.qNumberEmployees,
          qstocks: (data.qStocks === undefined) ? "" : data.qStocks,
          qbusinessproof: (data.qBusinessProof === undefined) ? "" : data.qBusinessProof,
          qbusinessactivity: (data.qBusinessActivity === undefined) ? "" : data.qBusinessActivity,
          qsignboardtext: (data.qSignBoardText === undefined) ? "" : data.qSignBoardText,
          qofficeseentext: (data.qOfficeSeenText === undefined) ? "" : data.qOfficeSeenText,
          qvehicledetails: (data.qVehicleDetails === undefined) ? "" : data.qVehicleDetails,
          qstability: (data.qStability === undefined) ? "" : data.qStability,
          customersignature: (data.qustomerSignature === undefined) ? "" : data.qustomerSignature,
          qtpc1: (data.qTpc1 === undefined) ? "" : data.qTpc1,
          qtpc1check: (data.qTpc1Check === undefined) ? "" : data.qTpc1Check,
          qtpc2: (data.qTpc2 === undefined) ? "" : data.qTpc2,
          qtpc2check: (data.qTpc2Check === undefined) ? "" : data.qTpc2Check,
          qcpvstatus: (data.qCpvStatus === undefined) ? "" : data.qCpvStatus,
          qrejectreason: (data.qRejectReason === undefined) ? "" : data.qRejectReason,
          qagentremarks: (data.qAgentRemarks === undefined) ? "" : data.qAgentRemarks,
          agentsignature: (data.agentSignature === undefined) ? "" : data.agentSignature,
          branchName: (data.branchName === undefined) ? "" : data.branchName,
          photos: (data.photos === undefined) ? "" : data.photos,
        })

        var config = {
            method: "put",
            url: API_BASE_URL + "/auth/case/" + caseId,
            headers: {
              apikey: API_KEY,
              Authorization: "Bearer " + getCookie("at"),
              "Content-Type": "application/json",
            },
            data: jsonData,
          }
      
          axios(config)
            .then(function (response) {
              if (response.status == 200) {
                alert("Updated Successfully!!")        
                comp.setState({
                    home: true
                })
              }
            })
            .catch(function (error) {
              console.log(error)
              comp.handleNon200Response(error, "3")
        })


}

handleUpdate3(e) {
    let data = this.state

    let comp = this

    let caseId = sessionStorage.getItem("caseId")

    let jsonData = JSON.stringify({
          qaddressconfirmed: (data.qAddressConfirmed === undefined) ? "" : data.qAddressConfirmed,
          qpersonmet: (data.qPersonMet === undefined) ? "" : data.qPersonMet,
          qpersonrelationship: (data.qPersonRelationship === undefined) ? "" : data.qPersonRelationship,
          qpersonstability: (data.qPersonStability === undefined) ? "" : data.qPersonStability,
          qresidenceownership: (data.qResidenceOwnership === undefined) ? "" : data.qResidenceOwnership,
          qfamilymemberscount: (data.qFamilyMembersCount === undefined) ? "" : data.qFamilyMembersCount,
          qearningmemberscount: (data.qEarningMembersCount === undefined) ? "" : data.qEarningMembersCount,
          qspouseworking: (data.qSpouseWorking === undefined) ? "" : data.qSpouseWorking,
          qearningmembers: (data.qEarningMembers === undefined) ? "" : data.qEarningMembers,
          qnbfcname: (data.qNbfcName === undefined) ? "" : data.qNbfcName,
          qloanamount: (data.qLoanAmount === undefined) ? "" : data.qLoanAmount,
          qpurpose: (data.qPurpose === undefined) ? "" : data.qPurpose,
          qpermanentaddress: (data.qPermanentAddress === undefined) ? "" : data.qPermanentAddress,
          qcontactperson: (data.qContactPerson === undefined) ? "" : data.qContactPerson,
          qrent: (data.qRent === undefined) ? "" : data.qRent,
          qverifiedfrom: (data.qVerifiedFrom === undefined) ? "" : data.qVerifiedFrom,
          qarea: (data.qArea === undefined) ? "" : data.qArea,
          qassetdetails: (data.qAssetDetails === undefined) ? "" : data.qAssetDetails,
          qassetappliedfor: (data.qAssetAppliedFor === undefined) ? "" : data.qAssetAppliedFor,
          qassettobeusedby: (data.qAssetToBeUsedBy === undefined) ? "" : data.qAssetToBeUsedBy,
          qeaseoflocate: (data.qEaseOfLocate === undefined) ? "" : data.qEaseOfLocate,
          qlandmark: (data.qLandmark === undefined) ? "" : data.qLandmark,
          qtypeofresidence: (data.qTypeOfResidence === undefined) ? "" : data.qTypeOfResidence,
          qlocalityofresidence: (data.qLocalityOfResidence === undefined) ? "" : data.qLocalityOfResidence,
          qareaofresidence: (data.qAreaofResidence === undefined) ? "" : data.qAreaofResidence,
          qresidenceconstruction: (data.qResidenceConstruction === undefined) ? "" : data.qResidenceConstruction,
          qcommentonexterior: (data.qCommentOnExterior === undefined) ? "" : data.qCommentOnExterior,
          qcommentoninterior: (data.qCommentOnInterior === undefined) ? "" : data.qCommentOnInterior,
          qassetseenatresidence: (data.qAssetSeenAtResidence === undefined) ? "" : data.qAssetSeenAtResidence,
          qtwowheeler: (data.qTwoWheeler === undefined) ? "" : data.qTwoWheeler,
          qtwowheelermodelname: (data.qTwoWheelerModelName === undefined) ? "" : data.qTwoWheelerModelName,
          qtwowheeleryear: (data.qTwoWheelerYear === undefined) ? "" : data.qTwoWheelerYear,
          qcar: (data.qCar === undefined) ? "" : data.qCar,
          qcarmodelname: (data.qCarModelName === undefined) ? "" : data.qCarModelName,
          qcaryear: (data.qCarYear === undefined) ? "" : data.qCarYear,
          qvehicleusedby: (data.qVehicleUsedBy === undefined) ? "" : data.qVehicleUsedBy,
          qpersonnameverification: (data.qPersonNameVerification === undefined) ? "" : data.qPersonNameVerification,
          qapplicantdetailsconfirmed: (data.qApplicantDetailsConfirmed === undefined) ? "" : data.qApplicantDetailsConfirmed,
          qnegativefeedback: (data.qNegativeFeedback === undefined) ? "" : data.qNegativeFeedback,
          qresidencelocked: (data.qResidenceLocked === undefined) ? "" : data.qResidenceLocked,
          qcpvstatus: (data.qCpvStatus === undefined) ? "" : data.qCpvStatus,
          qrejectreason: (data.qRejectReason === undefined) ? "" : data.qRejectReason,
          branchName: (data.branchName === undefined) ? "" : data.branchName,
          photos: (data.photos === undefined) ? "" : data.photos,
          qagentremarks: (data.qAgentRemarks === undefined) ? "" : data.qAgentRemarks,
        })

        var config = {
            method: "put",
            url: API_BASE_URL + "/auth/case/" + caseId,
            headers: {
              apikey: API_KEY,
              Authorization: "Bearer " + getCookie("at"),
              "Content-Type": "application/json",
            },
            data: jsonData,
          }
      
          axios(config)
            .then(function (response) {
              if (response.status == 200) {
                alert("Updated Successfully!!")        
                comp.setState({
                    home: true
                })
              }
            })
            .catch(function (error) {
              console.log(error)
              comp.handleNon200Response(error, "4")
        })
}

enableToClient() {
    let data = this.state
    let comp = this
    let caseId = sessionStorage.getItem("caseId")
 
    let jsonData = JSON.stringify({
          showToClient: "YES"
      })
 
      var config = {
          method: "put",
          url: API_BASE_URL + "/auth/case/" + caseId,
          headers: {
            apikey: API_KEY,
            Authorization: "Bearer " + getCookie("at"),
            "Content-Type": "application/json",
          },
          data: jsonData,
        }
    
        axios(config)
          .then(function (response) {
            if (response.status == 200) {
              alert("Enabled Successfully!!")     
              comp.setState({
                home: true
            })   
            }
          })
          .catch(function (error) {
            console.log(error)
            comp.handleNon200Response(error, "5")
      })
 }
 
 disableToClient() {
    let data = this.state
    let comp = this
    let caseId = sessionStorage.getItem("caseId")
 
    let jsonData = JSON.stringify({
          showToClient: "NO"
      })
 
      var config = {
          method: "put",
          url: API_BASE_URL + "/auth/case/" + caseId,
          headers: {
            apikey: API_KEY,
            Authorization: "Bearer " + getCookie("at"),
            "Content-Type": "application/json",
          },
          data: jsonData,
        }
    
        axios(config)
          .then(function (response) {
            if (response.status == 200) {
              alert("Disabled Successfully!!")     
              comp.setState({
                home: true
            }) 
            }
          })
          .catch(function (error) {
            console.log(error)
            comp.handleNon200Response(error, "6")
      })
    }


renderMarkers = (map, maps) => {

   let marker = new maps.Marker({
      position: { lat: parseFloat(this.state.markerLatitude), lng: parseFloat(this.state.markerLongitude) },
      map,
      title: this.state.customerName
   });
   return marker;
};


  agentsOnChange = agentId => {
    this.setState({ agentId });
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

    handleFileChange = async (event, type) => {
    let caseId = sessionStorage.getItem("caseId")
    const documentId = caseId;

    const files = Array.from(event.target.files);
    try {
      // Upload each file and get the URL
      const urls = await Promise.all(files.map(async (file) => {
        // const key = `uploads/${Date.now()}_${file.name}`;
        
        const key = `images/${documentId}/${Date.now()}_${file.name}`;
        const contentType = file.type;
        const fileUrl = await uploadFile(key, file, contentType);
        return fileUrl;
      }));

      if (type === 'photos') {
        this.setState(prevState => ({
          ...prevState,
          [type]: [...prevState[type], ...urls]
        }));
      }
      
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  render() {
    return (
      <React.Fragment>

        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Case" breadcrumbItem="Edit Case" />
            <FontAwesomeIcon id = "mainBack" style = {{color :"black", fontSize: "15px", cursor: "pointer"}} onClick={() => this.setState({ home: true })} icon={faArrowLeft}/>  <br/><br />
                    {this.state.home ?
                    <Redirect to = "/verified-case" /> : null }

                    <Row>
                        <Col md = "3"><b>Cutomer Name: </b> {this.state.customerName}</Col>
                        <Col md = "3"><b>Product: </b> {this.state.productName}</Col>
                        <Col md = "3"><b>Reference Number: </b> {this.state.applicationId}</Col>
                        <Col md = "3"><b>FI Type: </b> {this.state.fi}</Col>
                    </Row>

                    <br />
                    {this.state.fi === "RV" && this.state.productName.toLowerCase() === "agriloan" ?
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list">
                                    <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Address Confirmation</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAddressConfirmed"
                                                        placeholder="Address Confirmation"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAddressConfirmed"
                                                        value = {this.state.qAddressConfirmed}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Name of Person Met</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPersonMet"
                                                        placeholder="Name of Person Met"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPersonMet"
                                                        value = {this.state.qPersonMet}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Relation With Applicant</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPersonRelationship"
                                                        placeholder="Relation With Applicant"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPersonRelationship"
                                                        value = {this.state.qPersonRelationship}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Stability</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qStability"
                                                        placeholder="Stability"
                                                        type="text"
                                                        className="form-control"
                                                        id="qStability"
                                                        value = {this.state.qStability}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Total Agri Land</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qTotalAgriLand"
                                                        placeholder="Total Agri Land"
                                                        type="text"
                                                        className="form-control"
                                                        id="qTotalAgriLand"
                                                        value = {this.state.qTotalAgriLand}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Land Details (PATTA No)</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qLandDetails"
                                                        placeholder="Land Details (PATTA No)"
                                                        type="text"
                                                        className="form-control"
                                                        id="qLandDetails"
                                                        value = {this.state.qLandDetails}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Source of Irrigation</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qIrrigationSource"
                                                        placeholder="Source of Irrigation"
                                                        type="text"
                                                        className="form-control"
                                                        id="qIrrigationSource"
                                                        value = {this.state.qIrrigationSource}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Type of Crops</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCropType"
                                                        placeholder="Type of Crops"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCropType"
                                                        value = {this.state.qCropType}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Ownership Residence</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qResidenceOwnership"
                                                        placeholder="Ownership Residence"
                                                        type="text"
                                                        className="form-control"
                                                        id="qResidenceOwnership"
                                                        value = {this.state.qResidenceOwnership}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Total Family Members</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qFamilyMembersCount"
                                                        placeholder="Total Family Members"
                                                        type="text"
                                                        className="form-control"
                                                        id="qFamilyMembersCount"
                                                        value = {this.state.qFamilyMembersCount}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Number of Earning Members</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qEarningMembers"
                                                        placeholder="Number of Earning Members"
                                                        type="text"
                                                        className="form-control"
                                                        id="qEarningMembers"
                                                        value = {this.state.qEarningMembers}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Office Address</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qOfficeAddress"
                                                        placeholder="Office Address"
                                                        type="text"
                                                        className="form-control"
                                                        id="qOfficeAddress"
                                                        value = {this.state.qOfficeAddress}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Residence Proof Sighted</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qResidenceProofId"
                                                        placeholder="Residence Proof Sighted"
                                                        type="text"
                                                        className="form-control"
                                                        id="qResidenceProofId"
                                                        value = {this.state.qResidenceProofId}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Agriculture Lending (If Any)</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAgricultureLending"
                                                        placeholder="Agriculture Lending (If Any)"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAgricultureLending"
                                                        value = {this.state.qAgricultureLending}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Interior</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qInterior"
                                                        placeholder="Interior"
                                                        type="text"
                                                        className="form-control"
                                                        id="qInterior"
                                                        value = {this.state.qInterior}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Exterior</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qExterior"
                                                        placeholder="Exterior"
                                                        type="text"
                                                        className="form-control"
                                                        id="qExterior"
                                                        value = {this.state.qExterior}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Neighbour Check1</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qNeighbour1"
                                                        placeholder="Neighbour Check1"
                                                        type="text"
                                                        className="form-control"
                                                        id="qNeighbour1"
                                                        value = {this.state.qNeighbour1}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Neighbour Check 2</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qNeighbour2"
                                                        placeholder="Neighbour Check 2"
                                                        type="text"
                                                        className="form-control"
                                                        id="qNeighbour2"
                                                        value = {this.state.qNeighbour2}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>CPV Status</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCpvStatus"
                                                        placeholder="CPV Status"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCpvStatus"
                                                        value = {this.state.qCpvStatus}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Reject Reason</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qRejectReason"
                                                        placeholder="Reject Reason"
                                                        type="text"
                                                        className="form-control"
                                                        id="qRejectReason"
                                                        value = {this.state.qRejectReason}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Agent Remarks</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAgentRemarks"
                                                        placeholder="Asset Applied For"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAgentRemarks"
                                                        value = {this.state.qAgentRemarks}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Branch Name</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="branchName"
                                                        placeholder="Branch Name"
                                                        type="text"
                                                        className="form-control"
                                                        id="branchName"
                                                        value = {this.state.branchName}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Upload Images</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        type="file"
                                                        accept="image/png, image/gif, image/jpeg"
                                                        multiple
                                                        className="form-control"
                                                        onChange={(e) => this.handleFileChange(e, 'photos')}
                                                        // inputProps={{ accept: 'image/*' }}
                                                    />
                                                </Col>
                                            </Row>

                                            <Button type = "submit" onClick = {this.handleUpdate1.bind(this)} color = "primary">Update</Button> <br /><br />
                                            {this.state.showToClient == '' || this.state.showToClient == "NO" ?
                                                <Button type = "submit" onClick = {this.enableToClient.bind(this)} color = "danger">Enable to Client</Button> :
                                                <Button type = "submit" onClick = {this.disableToClient.bind(this)} color = "danger">Disable to Client</Button>
                                            }
                                    </CardBody>
                                </Card>

                            </Col>
                        </Row> : null }


                        {this.state.fi === "BV" ?
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list">
                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Person Met</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPersonMet"
                                                        placeholder="Person Met"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPersonMet"
                                                        value = {this.state.qPersonMet}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Upload Images</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        type="file"
                                                        accept="image/png, image/gif, image/jpeg"
                                                        multiple
                                                        className="form-control"
                                                        onChange={(e) => this.handleFileChange(e, 'photos')}
                                                        // inputProps={{ accept: 'image/*' }}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Person Met Designation</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPersonMetDesignation"
                                                        placeholder="Person Met Designation"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPersonMetDesignation"
                                                        value = {this.state.qPersonMetDesignation}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Name of Co. /Officer</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCoOffice"
                                                        placeholder="Name of Co. /Officer"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCoOffice"
                                                        value = {this.state.qCoOffice}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Address Confirmation</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAddressConfirmed"
                                                        placeholder="Address Confirmation"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAddressConfirmed"
                                                        value = {this.state.qAddressConfirmed}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Applicant Designation</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPersonDesignation"
                                                        placeholder="Applicant Designation"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPersonDesignation"
                                                        value = {this.state.qPersonDesignation}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Residence Address</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qResidentAddress"
                                                        placeholder="Residence Address"
                                                        type="text"
                                                        className="form-control"
                                                        id="qResidentAddress"
                                                        value = {this.state.qResidentAddress}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Nature of Business</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qNature"
                                                        placeholder="Nature of Business"
                                                        type="text"
                                                        className="form-control"
                                                        id="qNature"
                                                        value = {this.state.qNature}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Office Ownership</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qOfficeOwnership"
                                                        placeholder="Office Ownership"
                                                        type="text"
                                                        className="form-control"
                                                        id="qOfficeOwnership"
                                                        value = {this.state.qOfficeOwnership}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Working Since</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qWorkingSince"
                                                        placeholder="Working Since"
                                                        type="text"
                                                        className="form-control"
                                                        id="qWorkingSince"
                                                        value = {this.state.qWorkingSince}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Approx Income</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qApproxIncome"
                                                        placeholder="Approx Income"
                                                        type="text"
                                                        className="form-control"
                                                        id="qApproxIncome"
                                                        value = {this.state.qApproxIncome}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Number of Employees</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qNumberEmployees"
                                                        placeholder="Number of Employees"
                                                        type="text"
                                                        className="form-control"
                                                        id="qNumberEmployees"
                                                        value = {this.state.qNumberEmployees}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Stocks</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qStocks"
                                                        placeholder="Stocks"
                                                        type="text"
                                                        className="form-control"
                                                        id="qStocks"
                                                        value = {this.state.qStocks}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Nature of Business Proofs</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qBusinessProof"
                                                        placeholder="Nature of Business Proofs"
                                                        type="text"
                                                        className="form-control"
                                                        id="qBusinessProof"
                                                        value = {this.state.qBusinessProof}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Business Activity</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qBusinessActivity"
                                                        placeholder="Business Activity"
                                                        type="text"
                                                        className="form-control"
                                                        id="qBusinessActivity"
                                                        value = {this.state.qBusinessActivity}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Name Mentioned on SignBoard</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qSignBoardText"
                                                        placeholder="Name Mentioned on SignBoard"
                                                        type="text"
                                                        className="form-control"
                                                        id="qSignBoardText"
                                                        value = {this.state.qSignBoardText}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Proof of Office Seen</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qOfficeSeenText"
                                                        placeholder="Proof of Office Seen"
                                                        type="text"
                                                        className="form-control"
                                                        id="qOfficeSeenText"
                                                        value = {this.state.qOfficeSeenText}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Vehicle Details</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qVehicleDetails"
                                                        placeholder="Vehicle Details"
                                                        type="text"
                                                        className="form-control"
                                                        id="qVehicleDetails"
                                                        value = {this.state.qVehicleDetails}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Stability</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qStability"
                                                        placeholder="Stability"
                                                        type="text"
                                                        className="form-control"
                                                        id="qStability"
                                                        value = {this.state.qStability}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Customer Sign</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qustomerSignature"
                                                        placeholder="Customer Sign"
                                                        type="text"
                                                        className="form-control"
                                                        id="qustomerSignature"
                                                        value = {this.state.qustomerSignature}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>TPC 1 Name</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qTpc1"
                                                        placeholder="TPC 1 Name"
                                                        type="text"
                                                        className="form-control"
                                                        id="qTpc1"
                                                        value = {this.state.qTpc1}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>TPC 1 Check with</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qTpc1Check"
                                                        placeholder="TPC 1 Check with"
                                                        type="text"
                                                        className="form-control"
                                                        id="qTpc1Check"
                                                        value = {this.state.qTpc1Check}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>TPC 2 Name</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qTpc2"
                                                        placeholder="TPC 2 Name"
                                                        type="text"
                                                        className="form-control"
                                                        id="qTpc2"
                                                        value = {this.state.qTpc2}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>TPC 2 Check with</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qTpc2Check"
                                                        placeholder="TPC 2 Check with"
                                                        type="text"
                                                        className="form-control"
                                                        id="qTpc2Check"
                                                        value = {this.state.qTpc2Check}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Verifier Feedback</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCpvStatus"
                                                        placeholder="Verifier Feedback"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCpvStatus"
                                                        value = {this.state.qCpvStatus}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Reject Reason</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qRejectReason"
                                                        placeholder="Reject Reason"
                                                        type="text"
                                                        className="form-control"
                                                        id="qRejectReason"
                                                        value = {this.state.qRejectReason}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Agent Remarks</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAgentRemarks"
                                                        placeholder="Agent Remarks"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAgentRemarks"
                                                        value = {this.state.qAgentRemarks}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Branch Name</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="branchName"
                                                        placeholder="Branch Name"
                                                        type="text"
                                                        className="form-control"
                                                        id="branchName"
                                                        value = {this.state.branchName}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Button type = "submit"  onClick = {this.handleUpdate2.bind(this)}color = "primary">Update</Button> <br /><br />
                                            {this.state.showToClient == '' || this.state.showToClient == "NO" ?
                                                <Button type = "submit" onClick = {this.enableToClient.bind(this)} color = "danger">Enable to Client</Button> :
                                                <Button type = "submit" onClick = {this.disableToClient.bind(this)} color = "danger">Disable to Client</Button>
                                            }

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> : null }


                        {this.state.fi === "RV" && this.state.productName.toLowerCase() !== "agriloan" ?
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list">
                                    <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Address Confirmation</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAddressConfirmed"
                                                        placeholder="Address Confirmation"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAddressConfirmed"
                                                        value = {this.state.qAddressConfirmed}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Upload Images</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        type="file"
                                                        accept="image/png, image/gif, image/jpeg"
                                                        multiple
                                                        className="form-control"
                                                        onChange={(e) => this.handleFileChange(e, 'photos')}
                                                        // inputProps={{ accept: 'image/*' }}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Name of Person Met</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPersonMet"
                                                        placeholder="Name of Person Met"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPersonMet"
                                                        value = {this.state.qPersonMet}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Relation With Applicant</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPersonRelationship"
                                                        placeholder="Relation With Applicant"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPersonRelationship"
                                                        value = {this.state.qPersonRelationship}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Stability</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPersonStability"
                                                        placeholder="Stability"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPersonStability"
                                                        value = {this.state.qPersonStability}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Ownership Residenc</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qResidenceOwnership"
                                                        placeholder="Ownership Residenc"
                                                        type="text"
                                                        className="form-control"
                                                        id="qResidenceOwnership"
                                                        value = {this.state.qResidenceOwnership}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Total Family Members</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qFamilyMembersCount"
                                                        placeholder="Total Family Members"
                                                        type="text"
                                                        className="form-control"
                                                        id="qFamilyMembersCount"
                                                        value = {this.state.qFamilyMembersCount}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Number of Earning Members</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qEarningMembersCount"
                                                        placeholder="Number of Earning Members"
                                                        type="text"
                                                        className="form-control"
                                                        id="qEarningMembersCount"
                                                        value = {this.state.qEarningMembersCount}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Spouse Working</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qSpouseWorking"
                                                        placeholder="Spouse Working"
                                                        type="text"
                                                        className="form-control"
                                                        id="qSpouseWorking"
                                                        value = {this.state.qSpouseWorking}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Earning Members</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qWorkingSince"
                                                        placeholder="Earning Members"
                                                        type="text"
                                                        className="form-control"
                                                        id="qWorkingSince"
                                                        value = {this.state.qWorkingSince}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Approx Income</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qEarningMembers"
                                                        placeholder="Approx Income"
                                                        type="text"
                                                        className="form-control"
                                                        id="qEarningMembers"
                                                        value = {this.state.qEarningMembers}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>NBFC/Bank Name</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qNbfcName"
                                                        placeholder="NBFC/Bank Name"
                                                        type="text"
                                                        className="form-control"
                                                        id="qNbfcName"
                                                        value = {this.state.qNbfcName}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Loan Amoun</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qLoanAmount"
                                                        placeholder="Loan Amoun"
                                                        type="text"
                                                        className="form-control"
                                                        id="qLoanAmount"
                                                        value = {this.state.qLoanAmount}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Purpose</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPurpose"
                                                        placeholder="Purpose"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPurpose"
                                                        value = {this.state.qPurpose}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Resident Status</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qResidenceStatus"
                                                        placeholder="Resident Status"
                                                        type="text"
                                                        className="form-control"
                                                        id="qResidenceStatus"
                                                        value = {this.state.qResidenceStatus}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Permanent Address</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPermanentAddress"
                                                        placeholder="Permanent Address"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPermanentAddress"
                                                        value = {this.state.qPermanentAddress}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Contact Person</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qContactPerson"
                                                        placeholder="Contact Person"
                                                        type="text"
                                                        className="form-control"
                                                        id="qContactPerson"
                                                        value = {this.state.qContactPerson}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Rent Per Month</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qRent"
                                                        placeholder="Rent Per Month"
                                                        type="text"
                                                        className="form-control"
                                                        id="qRent"
                                                        value = {this.state.qRent}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Verified From</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qVerifiedFrom"
                                                        placeholder="Verified From"
                                                        type="text"
                                                        className="form-control"
                                                        id="qVerifiedFrom"
                                                        value = {this.state.qVerifiedFrom}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Area (In Sqft)</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qArea"
                                                        placeholder="Area (In Sqft)"
                                                        type="text"
                                                        className="form-control"
                                                        id="qArea"
                                                        value = {this.state.qArea}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Asset Details</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAssetDetails"
                                                        placeholder="Asset Details"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAssetDetails"
                                                        value = {this.state.qAssetDetails}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>


                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Asset Applied For</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAssetAppliedFor"
                                                        placeholder="Asset Applied For"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAssetAppliedFor"
                                                        value = {this.state.qAssetAppliedFor}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Asset To be Used By</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAssetToBeUsedBy"
                                                        placeholder="Asset To be Used By"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAssetToBeUsedBy"
                                                        value = {this.state.qAssetToBeUsedBy}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Ease of Locate Address</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qEaseOfLocate"
                                                        placeholder="Ease of Locate Address"
                                                        type="text"
                                                        className="form-control"
                                                        id="qEaseOfLocate"
                                                        value = {this.state.qEaseOfLocate}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Landmark</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qLandmark"
                                                        placeholder="Landmark"
                                                        type="text"
                                                        className="form-control"
                                                        id="qLandmark"
                                                        value = {this.state.qLandmark}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Type of Residence</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qTypeOfResidence"
                                                        placeholder="Type of Residence"
                                                        type="text"
                                                        className="form-control"
                                                        id="qTypeOfResidence"
                                                        value = {this.state.qTypeOfResidence}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Locality of Residence</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qLocalityOfResidence"
                                                        placeholder="Locality of Residence"
                                                        type="text"
                                                        className="form-control"
                                                        id="qLocalityOfResidence"
                                                        value = {this.state.qLocalityOfResidence}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Area of Residence</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAreaofResidence"
                                                        placeholder="Area of Residence"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAreaofResidence"
                                                        value = {this.state.qAreaofResidence}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Residence Construction</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qResidenceConstruction"
                                                        placeholder="Residence Construction"
                                                        type="text"
                                                        className="form-control"
                                                        id="qResidenceConstruction"
                                                        value = {this.state.qResidenceConstruction}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Comment on Exterior</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCommentOnExterior"
                                                        placeholder="Comment on Exterior"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCommentOnExterior"
                                                        value = {this.state.qCommentOnExterior}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Comment on Interior</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCommentOnInterior"
                                                        placeholder="Comment on Interior"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCommentOnInterior"
                                                        value = {this.state.qCommentOnInterior}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Asset seen at Residence</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAssetSeenAtResidence"
                                                        placeholder="Asset seen at Residence"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAssetSeenAtResidence"
                                                        value = {this.state.qAssetSeenAtResidence}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Two Wheeler</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qTwoWheeler"
                                                        placeholder="Two Wheeler"
                                                        type="text"
                                                        className="form-control"
                                                        id="qTwoWheeler"
                                                        value = {this.state.qTwoWheeler}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Model Name</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qTwoWheelerModelName"
                                                        placeholder="Model Name"
                                                        type="text"
                                                        className="form-control"
                                                        id="qTwoWheelerModelName"
                                                        value = {this.state.qTwoWheelerModelName}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Year of Manufacture</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qTwoWheelerYear"
                                                        placeholder="Year of Manufacture"
                                                        type="text"
                                                        className="form-control"
                                                        id="qTwoWheelerYear"
                                                        value = {this.state.qTwoWheelerYear}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Car</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCar"
                                                        placeholder="Car"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCar"
                                                        value = {this.state.qCar}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Car Model</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCarModelName"
                                                        placeholder="Car Model"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCarModelName"
                                                        value = {this.state.qCarModelName}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Car Manufacturing Year</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCarYear"
                                                        placeholder="Car Manufacturing Year"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCarYear"
                                                        value = {this.state.qCarYear}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Vehicle to be Used By</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qVehicleUsedBy"
                                                        placeholder="Vehicle to be Used By"
                                                        type="text"
                                                        className="form-control"
                                                        id="qVehicleUsedBy"
                                                        value = {this.state.qVehicleUsedBy}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Name of the Person</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPersonNameVerification"
                                                        placeholder="Name of the Person"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPersonNameVerification"
                                                        value = {this.state.qPersonNameVerification}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Applicant Details Confirmed</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qApplicantDetailsConfirmed"
                                                        placeholder="Applicant Details Confirmed"
                                                        type="text"
                                                        className="form-control"
                                                        id="qApplicantDetailsConfirmed"
                                                        value = {this.state.qApplicantDetailsConfirmed}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Negative Feedback (If Any)</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qNegativeFeedback"
                                                        placeholder="Negative Feedback (If Any)"
                                                        type="text"
                                                        className="form-control"
                                                        id="qNegativeFeedback"
                                                        value = {this.state.qNegativeFeedback}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Resident Locked During Visit</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qResidenceLocked"
                                                        placeholder="Resident Locked During Visit"
                                                        type="text"
                                                        className="form-control"
                                                        id="qResidenceLocked"
                                                        value = {this.state.qResidenceLocked}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>CPV Status</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCpvStatus"
                                                        placeholder="CPV Status"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCpvStatus"
                                                        value = {this.state.qCpvStatus}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Reject Reason</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qRejectReason"
                                                        placeholder="Reject Reason"
                                                        type="text"
                                                        className="form-control"
                                                        id="qRejectReason"
                                                        value = {this.state.qRejectReason}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Agent Remarks</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAgentRemarks"
                                                        placeholder="Agent Remarks"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAgentRemarks"
                                                        value = {this.state.qAgentRemarks}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Branch Name</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="branchName"
                                                        placeholder="Branch Name"
                                                        type="text"
                                                        className="form-control"
                                                        id="branchName"
                                                        value = {this.state.branchName}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Button type = "submit" onClick = {this.handleUpdate3.bind(this)} color = "primary">Update</Button> <br /><br />
                                            {this.state.showToClient == '' || this.state.showToClient == "NO" ?
                                                <Button type = "submit" onClick = {this.enableToClient.bind(this)} color = "danger">Enable to Client</Button> :
                                                <Button type = "submit" onClick = {this.disableToClient.bind(this)} color = "danger">Disable to Client</Button>
                                            }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> : null }

                        {this.state.fi === "PV" && this.state.productName.toLowerCase() !== "agriloan" ?
                        <Row>
                            <Col lg={12}>
                            <Card>
                                    <CardBody id = "user-list">
                                    <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Address Confirmation</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAddressConfirmed"
                                                        placeholder="Address Confirmation"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAddressConfirmed"
                                                        value = {this.state.qAddressConfirmed}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Upload Images</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        type="file"
                                                        accept="image/png, image/gif, image/jpeg"
                                                        multiple
                                                        className="form-control"
                                                        onChange={(e) => this.handleFileChange(e, 'photos')}
                                                        // inputProps={{ accept: 'image/*' }}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Name of Person Met</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPersonMet"
                                                        placeholder="Name of Person Met"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPersonMet"
                                                        value = {this.state.qPersonMet}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Relation With Applicant</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPersonRelationship"
                                                        placeholder="Relation With Applicant"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPersonRelationship"
                                                        value = {this.state.qPersonRelationship}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Stability</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPersonStability"
                                                        placeholder="Stability"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPersonStability"
                                                        value = {this.state.qPersonStability}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Ownership Residenc</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qResidenceOwnership"
                                                        placeholder="Ownership Residenc"
                                                        type="text"
                                                        className="form-control"
                                                        id="qResidenceOwnership"
                                                        value = {this.state.qResidenceOwnership}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Total Family Members</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qFamilyMembersCount"
                                                        placeholder="Total Family Members"
                                                        type="text"
                                                        className="form-control"
                                                        id="qFamilyMembersCount"
                                                        value = {this.state.qFamilyMembersCount}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Number of Earning Members</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qEarningMembersCount"
                                                        placeholder="Number of Earning Members"
                                                        type="text"
                                                        className="form-control"
                                                        id="qEarningMembersCount"
                                                        value = {this.state.qEarningMembersCount}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Spouse Working</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qSpouseWorking"
                                                        placeholder="Spouse Working"
                                                        type="text"
                                                        className="form-control"
                                                        id="qSpouseWorking"
                                                        value = {this.state.qSpouseWorking}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Earning Members</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qWorkingSince"
                                                        placeholder="Earning Members"
                                                        type="text"
                                                        className="form-control"
                                                        id="qWorkingSince"
                                                        value = {this.state.qWorkingSince}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Approx Income</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qEarningMembers"
                                                        placeholder="Approx Income"
                                                        type="text"
                                                        className="form-control"
                                                        id="qEarningMembers"
                                                        value = {this.state.qEarningMembers}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>NBFC/Bank Name</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qNbfcName"
                                                        placeholder="NBFC/Bank Name"
                                                        type="text"
                                                        className="form-control"
                                                        id="qNbfcName"
                                                        value = {this.state.qNbfcName}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Loan Amoun</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qLoanAmount"
                                                        placeholder="Loan Amoun"
                                                        type="text"
                                                        className="form-control"
                                                        id="qLoanAmount"
                                                        value = {this.state.qLoanAmount}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Purpose</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPurpose"
                                                        placeholder="Purpose"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPurpose"
                                                        value = {this.state.qPurpose}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Resident Status</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qResidenceStatus"
                                                        placeholder="Resident Status"
                                                        type="text"
                                                        className="form-control"
                                                        id="qResidenceStatus"
                                                        value = {this.state.qResidenceStatus}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Permanent Address</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPermanentAddress"
                                                        placeholder="Permanent Address"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPermanentAddress"
                                                        value = {this.state.qPermanentAddress}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Contact Person</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qContactPerson"
                                                        placeholder="Contact Person"
                                                        type="text"
                                                        className="form-control"
                                                        id="qContactPerson"
                                                        value = {this.state.qContactPerson}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Rent Per Month</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qRent"
                                                        placeholder="Rent Per Month"
                                                        type="text"
                                                        className="form-control"
                                                        id="qRent"
                                                        value = {this.state.qRent}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Verified From</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qVerifiedFrom"
                                                        placeholder="Verified From"
                                                        type="text"
                                                        className="form-control"
                                                        id="qVerifiedFrom"
                                                        value = {this.state.qVerifiedFrom}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Area (In Sqft)</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qArea"
                                                        placeholder="Area (In Sqft)"
                                                        type="text"
                                                        className="form-control"
                                                        id="qArea"
                                                        value = {this.state.qArea}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Asset Details</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAssetDetails"
                                                        placeholder="Asset Details"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAssetDetails"
                                                        value = {this.state.qAssetDetails}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>


                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Asset Applied For</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAssetAppliedFor"
                                                        placeholder="Asset Applied For"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAssetAppliedFor"
                                                        value = {this.state.qAssetAppliedFor}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Asset To be Used By</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAssetToBeUsedBy"
                                                        placeholder="Asset To be Used By"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAssetToBeUsedBy"
                                                        value = {this.state.qAssetToBeUsedBy}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Ease of Locate Address</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qEaseOfLocate"
                                                        placeholder="Ease of Locate Address"
                                                        type="text"
                                                        className="form-control"
                                                        id="qEaseOfLocate"
                                                        value = {this.state.qEaseOfLocate}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Landmark</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qLandmark"
                                                        placeholder="Landmark"
                                                        type="text"
                                                        className="form-control"
                                                        id="qLandmark"
                                                        value = {this.state.qLandmark}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Type of Residence</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qTypeOfResidence"
                                                        placeholder="Type of Residence"
                                                        type="text"
                                                        className="form-control"
                                                        id="qTypeOfResidence"
                                                        value = {this.state.qTypeOfResidence}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Locality of Residence</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qLocalityOfResidence"
                                                        placeholder="Locality of Residence"
                                                        type="text"
                                                        className="form-control"
                                                        id="qLocalityOfResidence"
                                                        value = {this.state.qLocalityOfResidence}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Area of Residence</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAreaofResidence"
                                                        placeholder="Area of Residence"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAreaofResidence"
                                                        value = {this.state.qAreaofResidence}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Residence Construction</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qResidenceConstruction"
                                                        placeholder="Residence Construction"
                                                        type="text"
                                                        className="form-control"
                                                        id="qResidenceConstruction"
                                                        value = {this.state.qResidenceConstruction}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Comment on Exterior</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCommentOnExterior"
                                                        placeholder="Comment on Exterior"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCommentOnExterior"
                                                        value = {this.state.qCommentOnExterior}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Comment on Interior</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCommentOnInterior"
                                                        placeholder="Comment on Interior"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCommentOnInterior"
                                                        value = {this.state.qCommentOnInterior}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Asset seen at Residence</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAssetSeenAtResidence"
                                                        placeholder="Asset seen at Residence"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAssetSeenAtResidence"
                                                        value = {this.state.qAssetSeenAtResidence}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Two Wheeler</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qTwoWheeler"
                                                        placeholder="Two Wheeler"
                                                        type="text"
                                                        className="form-control"
                                                        id="qTwoWheeler"
                                                        value = {this.state.qTwoWheeler}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Model Name</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qTwoWheelerModelName"
                                                        placeholder="Model Name"
                                                        type="text"
                                                        className="form-control"
                                                        id="qTwoWheelerModelName"
                                                        value = {this.state.qTwoWheelerModelName}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Year of Manufacture</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qTwoWheelerYear"
                                                        placeholder="Year of Manufacture"
                                                        type="text"
                                                        className="form-control"
                                                        id="qTwoWheelerYear"
                                                        value = {this.state.qTwoWheelerYear}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Car</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCar"
                                                        placeholder="Car"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCar"
                                                        value = {this.state.qCar}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Car Model</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCarModelName"
                                                        placeholder="Car Model"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCarModelName"
                                                        value = {this.state.qCarModelName}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Car Manufacturing Year</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCarYear"
                                                        placeholder="Car Manufacturing Year"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCarYear"
                                                        value = {this.state.qCarYear}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Vehicle to be Used By</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qVehicleUsedBy"
                                                        placeholder="Vehicle to be Used By"
                                                        type="text"
                                                        className="form-control"
                                                        id="qVehicleUsedBy"
                                                        value = {this.state.qVehicleUsedBy}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Name of the Person</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPersonNameVerification"
                                                        placeholder="Name of the Person"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPersonNameVerification"
                                                        value = {this.state.qPersonNameVerification}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Applicant Details Confirmed</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qApplicantDetailsConfirmed"
                                                        placeholder="Applicant Details Confirmed"
                                                        type="text"
                                                        className="form-control"
                                                        id="qApplicantDetailsConfirmed"
                                                        value = {this.state.qApplicantDetailsConfirmed}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Negative Feedback (If Any)</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qNegativeFeedback"
                                                        placeholder="Negative Feedback (If Any)"
                                                        type="text"
                                                        className="form-control"
                                                        id="qNegativeFeedback"
                                                        value = {this.state.qNegativeFeedback}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Resident Locked During Visit</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qResidenceLocked"
                                                        placeholder="Resident Locked During Visit"
                                                        type="text"
                                                        className="form-control"
                                                        id="qResidenceLocked"
                                                        value = {this.state.qResidenceLocked}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>CPV Status</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCpvStatus"
                                                        placeholder="CPV Status"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCpvStatus"
                                                        value = {this.state.qCpvStatus}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Reject Reason</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qRejectReason"
                                                        placeholder="Reject Reason"
                                                        type="text"
                                                        className="form-control"
                                                        id="qRejectReason"
                                                        value = {this.state.qRejectReason}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Branch Name</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="branchName"
                                                        placeholder="Branch Name"
                                                        type="text"
                                                        className="form-control"
                                                        id="branchName"
                                                        value = {this.state.branchName}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Button type = "submit" onClick = {this.handleUpdate3.bind(this)} color = "primary">Update</Button> <br /><br />
                                            {this.state.showToClient == '' || this.state.showToClient == "NO" ?
                                                <Button type = "submit" onClick = {this.enableToClient.bind(this)} color = "danger">Enable to Client</Button> :
                                                <Button type = "submit" onClick = {this.disableToClient.bind(this)} color = "danger">Disable to Client</Button>
                                            }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> : null }

                        {this.state.fi !== "PV" && this.state.fi !== "BV" && this.state.fi !== "RV" ?
                        <Row>
                            <Col lg={12}>
                            <Card>
                                    <CardBody id = "user-list">
                                    <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Address Confirmation</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAddressConfirmed"
                                                        placeholder="Address Confirmation"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAddressConfirmed"
                                                        value = {this.state.qAddressConfirmed}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Upload Images</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        type="file"
                                                        accept="image/png, image/gif, image/jpeg"
                                                        multiple
                                                        className="form-control"
                                                        onChange={(e) => this.handleFileChange(e, 'photos')}
                                                        // inputProps={{ accept: 'image/*' }}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Name of Person Met</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPersonMet"
                                                        placeholder="Name of Person Met"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPersonMet"
                                                        value = {this.state.qPersonMet}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Relation With Applicant</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPersonRelationship"
                                                        placeholder="Relation With Applicant"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPersonRelationship"
                                                        value = {this.state.qPersonRelationship}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Stability</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPersonStability"
                                                        placeholder="Stability"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPersonStability"
                                                        value = {this.state.qPersonStability}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Ownership Residenc</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qResidenceOwnership"
                                                        placeholder="Ownership Residenc"
                                                        type="text"
                                                        className="form-control"
                                                        id="qResidenceOwnership"
                                                        value = {this.state.qResidenceOwnership}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Total Family Members</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qFamilyMembersCount"
                                                        placeholder="Total Family Members"
                                                        type="text"
                                                        className="form-control"
                                                        id="qFamilyMembersCount"
                                                        value = {this.state.qFamilyMembersCount}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Number of Earning Members</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qEarningMembersCount"
                                                        placeholder="Number of Earning Members"
                                                        type="text"
                                                        className="form-control"
                                                        id="qEarningMembersCount"
                                                        value = {this.state.qEarningMembersCount}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Spouse Working</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qSpouseWorking"
                                                        placeholder="Spouse Working"
                                                        type="text"
                                                        className="form-control"
                                                        id="qSpouseWorking"
                                                        value = {this.state.qSpouseWorking}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Earning Members</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qWorkingSince"
                                                        placeholder="Earning Members"
                                                        type="text"
                                                        className="form-control"
                                                        id="qWorkingSince"
                                                        value = {this.state.qWorkingSince}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Approx Income</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qEarningMembers"
                                                        placeholder="Approx Income"
                                                        type="text"
                                                        className="form-control"
                                                        id="qEarningMembers"
                                                        value = {this.state.qEarningMembers}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>NBFC/Bank Name</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qNbfcName"
                                                        placeholder="NBFC/Bank Name"
                                                        type="text"
                                                        className="form-control"
                                                        id="qNbfcName"
                                                        value = {this.state.qNbfcName}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Loan Amoun</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qLoanAmount"
                                                        placeholder="Loan Amoun"
                                                        type="text"
                                                        className="form-control"
                                                        id="qLoanAmount"
                                                        value = {this.state.qLoanAmount}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Purpose</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPurpose"
                                                        placeholder="Purpose"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPurpose"
                                                        value = {this.state.qPurpose}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Resident Status</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qResidenceStatus"
                                                        placeholder="Resident Status"
                                                        type="text"
                                                        className="form-control"
                                                        id="qResidenceStatus"
                                                        value = {this.state.qResidenceStatus}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Permanent Address</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPermanentAddress"
                                                        placeholder="Permanent Address"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPermanentAddress"
                                                        value = {this.state.qPermanentAddress}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Contact Person</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qContactPerson"
                                                        placeholder="Contact Person"
                                                        type="text"
                                                        className="form-control"
                                                        id="qContactPerson"
                                                        value = {this.state.qContactPerson}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Rent Per Month</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qRent"
                                                        placeholder="Rent Per Month"
                                                        type="text"
                                                        className="form-control"
                                                        id="qRent"
                                                        value = {this.state.qRent}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Verified From</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qVerifiedFrom"
                                                        placeholder="Verified From"
                                                        type="text"
                                                        className="form-control"
                                                        id="qVerifiedFrom"
                                                        value = {this.state.qVerifiedFrom}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Area (In Sqft)</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qArea"
                                                        placeholder="Area (In Sqft)"
                                                        type="text"
                                                        className="form-control"
                                                        id="qArea"
                                                        value = {this.state.qArea}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Asset Details</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAssetDetails"
                                                        placeholder="Asset Details"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAssetDetails"
                                                        value = {this.state.qAssetDetails}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>


                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Asset Applied For</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAssetAppliedFor"
                                                        placeholder="Asset Applied For"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAssetAppliedFor"
                                                        value = {this.state.qAssetAppliedFor}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                            <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Asset To be Used By</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAssetToBeUsedBy"
                                                        placeholder="Asset To be Used By"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAssetToBeUsedBy"
                                                        value = {this.state.qAssetToBeUsedBy}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Ease of Locate Address</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qEaseOfLocate"
                                                        placeholder="Ease of Locate Address"
                                                        type="text"
                                                        className="form-control"
                                                        id="qEaseOfLocate"
                                                        value = {this.state.qEaseOfLocate}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Landmark</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qLandmark"
                                                        placeholder="Landmark"
                                                        type="text"
                                                        className="form-control"
                                                        id="qLandmark"
                                                        value = {this.state.qLandmark}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Type of Residence</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qTypeOfResidence"
                                                        placeholder="Type of Residence"
                                                        type="text"
                                                        className="form-control"
                                                        id="qTypeOfResidence"
                                                        value = {this.state.qTypeOfResidence}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Locality of Residence</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qLocalityOfResidence"
                                                        placeholder="Locality of Residence"
                                                        type="text"
                                                        className="form-control"
                                                        id="qLocalityOfResidence"
                                                        value = {this.state.qLocalityOfResidence}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Area of Residence</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAreaofResidence"
                                                        placeholder="Area of Residence"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAreaofResidence"
                                                        value = {this.state.qAreaofResidence}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Residence Construction</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qResidenceConstruction"
                                                        placeholder="Residence Construction"
                                                        type="text"
                                                        className="form-control"
                                                        id="qResidenceConstruction"
                                                        value = {this.state.qResidenceConstruction}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Comment on Exterior</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCommentOnExterior"
                                                        placeholder="Comment on Exterior"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCommentOnExterior"
                                                        value = {this.state.qCommentOnExterior}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Comment on Interior</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCommentOnInterior"
                                                        placeholder="Comment on Interior"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCommentOnInterior"
                                                        value = {this.state.qCommentOnInterior}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Asset seen at Residence</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qAssetSeenAtResidence"
                                                        placeholder="Asset seen at Residence"
                                                        type="text"
                                                        className="form-control"
                                                        id="qAssetSeenAtResidence"
                                                        value = {this.state.qAssetSeenAtResidence}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Two Wheeler</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qTwoWheeler"
                                                        placeholder="Two Wheeler"
                                                        type="text"
                                                        className="form-control"
                                                        id="qTwoWheeler"
                                                        value = {this.state.qTwoWheeler}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Model Name</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qTwoWheelerModelName"
                                                        placeholder="Model Name"
                                                        type="text"
                                                        className="form-control"
                                                        id="qTwoWheelerModelName"
                                                        value = {this.state.qTwoWheelerModelName}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Year of Manufacture</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qTwoWheelerYear"
                                                        placeholder="Year of Manufacture"
                                                        type="text"
                                                        className="form-control"
                                                        id="qTwoWheelerYear"
                                                        value = {this.state.qTwoWheelerYear}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Car</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCar"
                                                        placeholder="Car"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCar"
                                                        value = {this.state.qCar}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Car Model</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCarModelName"
                                                        placeholder="Car Model"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCarModelName"
                                                        value = {this.state.qCarModelName}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Car Manufacturing Year</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCarYear"
                                                        placeholder="Car Manufacturing Year"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCarYear"
                                                        value = {this.state.qCarYear}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Vehicle to be Used By</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qVehicleUsedBy"
                                                        placeholder="Vehicle to be Used By"
                                                        type="text"
                                                        className="form-control"
                                                        id="qVehicleUsedBy"
                                                        value = {this.state.qVehicleUsedBy}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Name of the Person</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qPersonNameVerification"
                                                        placeholder="Name of the Person"
                                                        type="text"
                                                        className="form-control"
                                                        id="qPersonNameVerification"
                                                        value = {this.state.qPersonNameVerification}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Applicant Details Confirmed</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qApplicantDetailsConfirmed"
                                                        placeholder="Applicant Details Confirmed"
                                                        type="text"
                                                        className="form-control"
                                                        id="qApplicantDetailsConfirmed"
                                                        value = {this.state.qApplicantDetailsConfirmed}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Negative Feedback (If Any)</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qNegativeFeedback"
                                                        placeholder="Negative Feedback (If Any)"
                                                        type="text"
                                                        className="form-control"
                                                        id="qNegativeFeedback"
                                                        value = {this.state.qNegativeFeedback}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Resident Locked During Visit</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qResidenceLocked"
                                                        placeholder="Resident Locked During Visit"
                                                        type="text"
                                                        className="form-control"
                                                        id="qResidenceLocked"
                                                        value = {this.state.qResidenceLocked}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>CPV Status</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qCpvStatus"
                                                        placeholder="CPV Status"
                                                        type="text"
                                                        className="form-control"
                                                        id="qCpvStatus"
                                                        value = {this.state.qCpvStatus}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Reject Reason</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="qRejectReason"
                                                        placeholder="Reject Reason"
                                                        type="text"
                                                        className="form-control"
                                                        id="qRejectReason"
                                                        value = {this.state.qRejectReason}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 2}}>
                                                    <Label>Branch Name</Label>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <Input
                                                        name="branchName"
                                                        placeholder="Branch Name"
                                                        type="text"
                                                        className="form-control"
                                                        id="branchName"
                                                        value = {this.state.branchName}
                                                        onChange = {this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Button type = "submit" onClick = {this.handleUpdate3.bind(this)} color = "primary">Update</Button> <br /><br />
                                            {this.state.showToClient == '' || this.state.showToClient == "NO" ?
                                                <Button type = "submit" onClick = {this.enableToClient.bind(this)} color = "danger">Enable to Client</Button> :
                                                <Button type = "submit" onClick = {this.disableToClient.bind(this)} color = "danger">Disable to Client</Button>
                                            }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> : null }
          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default EditCaseDetails;